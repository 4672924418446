import * as React from 'react'
import { graphql } from 'gatsby'
import ArticleTemplate from '../../templates/article-template'
import WorkTemplate from '../../templates/work-template'

const Content = ({ data, location }) => {
  const collection = data.mdx.fields.collection;

  const templates = {
    articles: <ArticleTemplate data={data} location={location} />,
    work: <WorkTemplate data={data} />,
  }

  return (
    <>
      {templates[collection] ? templates[collection] : null}
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        subtitle
        date
        date_formatted
        hero_image_alt
        excerpt
        hero_image_credit_link
        hero_image_credit_text
        heroArticles: hero_image {
          publicURL
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.777777778)
          }
        }
        heroWork: hero_image {
          publicURL
          childImageSharp {
            gatsbyImageData(aspectRatio: 1)
          }
        }
        url
        info_project
        info_role
        info_agency
        info_agency_url
        info_awards
        info_tech
        credits
        work_block_1_label
        work_block_1 {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        work_block_2_label
        work_block_2 {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        work_block_3_label
        work_block_3 {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        work_block_4_label
        work_block_4 {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        testimonial
        testimonial_author
      }
      fields {
        collection
      }
      body
      slug
      excerpt(truncate: true, pruneLength: 130)
      rawBody

    }
  }
`

export default Content