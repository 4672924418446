import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions';
import WorkBlock from '../components/work-block'
import TestimonialBlock from '../components/testimonial-block'
import Testimonials from '../components/testimonials'
import Press from '../components/press'

const WorkTemplate = ({ data, location }) => {

  const imageData = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  const image = imageData.allFile.edges.find(el => el.node.name === 'marianne-ferguson-ultrafine-founder-3');

  const meta = {
    description: data.mdx.excerpt,
    image: `https://www.ultrafine.co${data.mdx.frontmatter.heroWork.publicURL}`
  }

  const awards = () => {
    if (data.mdx.frontmatter.info_awards) {
      return (
        <tr>
          <th className="work-info__title">Awards</th>
          <td>{data.mdx.frontmatter.info_awards}</td>
        </tr>
      )
    }
  }

  const credits = () => {
    if (data.mdx.frontmatter.credits) {
      return (
        <tr>
          <th className="work-info__title">Credits</th>
          <td>{data.mdx.frontmatter.credits}</td>
        </tr>
      )
    }
  }

  return (
    <Layout pageTitle={data.mdx.frontmatter.title} pageClass="template-work template-work--flush" meta={meta} location={location}>
      <PageTransition>
        <div className="container">
          <div className="work grid">
            <div className="work__image-wrapper">
              <GatsbyImage
                image={getImage(data.mdx.frontmatter.heroWork)}
                alt={data.mdx.frontmatter.hero_image_alt}
                className="work__image"
              />
            </div>
            <div className="work__content">
              <div className="work__text">
                <h1 className="work__title">{data.mdx.frontmatter.title}</h1>
                <div className="work__body md">
                  <MDXRenderer>
                    {data.mdx.body}
                  </MDXRenderer>
                </div>
              </div>
              <div className="work__info">
                <hr className="divider" />
                <table className="work-info md">
                  <tbody className="work-info__table-body">
                    <tr>
                      <th className="work-info__title">Project(s)</th>
                      <td>{data.mdx.frontmatter.info_project}</td>
                    </tr>
                    <tr>
                      <th className="work-info__title">Services</th>
                      <td>{data.mdx.frontmatter.info_role}</td>
                    </tr>
                    {data.mdx.frontmatter.info_agency_url &&
                      <tr>
                        <th className="work-info__title">Partner</th>
                        <td>
                          {data.mdx.frontmatter.info_agency_url ?
                          <a target="_blank" href={data.mdx.frontmatter.info_agency_url}>{data.mdx.frontmatter.info_agency}</a> :
                          <span>{data.mdx.frontmatter.info_agency}</span>}
                        </td>
                      </tr>
                    }
                    {data.mdx.frontmatter.info_tech &&
                      <tr>
                        <th className="work-info__title">Tech & Tools</th>
                        <td>{data.mdx.frontmatter.info_tech}</td>
                      </tr>
                    }
                    {awards()}
                    {credits()}
                  </tbody>
                </table>
                <div className="divider-button">
                <hr className="divider" />
                  <div className="divider-button__button-wrapper">
                    <Link to={data.mdx.frontmatter.url} className="button button--tertiary" target="_blank">
                      Visit website
                      <svg width="20" height="8.5" viewBox="0 0 20 8.26" aria-hidden="true" focusable="false" className="button__icon">
                        <path d="M15.11,7.47l3.32-3.34L15.11.79,15.89,0,20,4.13,15.89,8.26Z" />
                        <path d="M19.31,4.69H0V3.57H19.31Z" />
                      </svg>
                    </Link>
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
          </div>
          {data.mdx.frontmatter.work_block_1 &&
            <WorkBlock title={data.mdx.frontmatter.work_block_1_label} client={data.mdx.frontmatter.title} image={getImage(data.mdx.frontmatter.work_block_1)} />
          }
          {data.mdx.frontmatter.work_block_2 &&
            <WorkBlock title={data.mdx.frontmatter.work_block_2_label} client={data.mdx.frontmatter.title} image={getImage(data.mdx.frontmatter.work_block_2)} />
          }
          {data.mdx.frontmatter.work_block_3 &&
            <WorkBlock title={data.mdx.frontmatter.work_block_3_label} client={data.mdx.frontmatter.title} image={getImage(data.mdx.frontmatter.work_block_3)} />
          }
          {data.mdx.frontmatter.work_block_4 &&
            <WorkBlock title={data.mdx.frontmatter.work_block_4_label} client={data.mdx.frontmatter.title} image={getImage(data.mdx.frontmatter.work_block_4)} />
          }
          {data.mdx.frontmatter.testimonial &&
            <TestimonialBlock text={data.mdx.frontmatter.testimonial} author={data.mdx.frontmatter.testimonial_author} />
          }
        </div>
        <Testimonials />
        <Press />
      </PageTransition>
    </Layout>
  )
}

export default WorkTemplate