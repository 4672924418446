import * as React from 'react'

const WorkBlock = ({ text, author }) => {
  return (
    <div className="testimonial-block grid">
      <div className="testimonial-block__title">
        <h2 className="h4">Client testimonial</h2>
      </div>
      <div className="testimonial-block__content">
        <blockquote>
          <div dangerouslySetInnerHTML={{ __html: text}}></div>
          <footer>
            <cite>
              <p className='h6'>{author}</p>
            </cite>
          </footer>
        </blockquote>
      </div>
    </div>
  )
}

export default WorkBlock