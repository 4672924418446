import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const WorkBlock = ({ title, client, image }) => {
  return (
    <div className="work-block grid">
      <div className="work-block__title">
        <h2 className="h4">{title}</h2>
      </div>
      <div className="work-block__image">
        <GatsbyImage
          image={image}
          alt={`Screenshot of ${title} design for ${client}`}
          />
      </div>
    </div>
  )
}

export default WorkBlock