import * as React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions';
import Testimonials from '../components/testimonials'
import Press from '../components/press'

const ArticleTemplate = ({ data, location }) => {
  const meta = {
    type: 'article',
    url: `https://www.ultrafine.co${location.pathname}`,
    title: data.mdx.frontmatter.title,
    description: data.mdx.excerpt,
    image: `https://www.ultrafine.co${data.mdx.frontmatter.heroArticles.publicURL}`
  }

  const schema = `{ 
    "@context": "https://schema.org",
    "@type": "Article",
    "url": "https://www.ultrafine.co/articles/${data.mdx.slug}",
    "mainEntityOfPage": "https://www.ultrafine.co/articles/${data.mdx.slug}",
    "name": "${data.mdx.frontmatter.title}",
    "headline": "${data.mdx.frontmatter.title}",
    "image": "https://www.ultrafine.co${data.mdx.frontmatter.heroArticles.publicURL}",
    "datePublished": "${data.mdx.frontmatter.date}",
    "description": "${data.mdx.excerpt}",
    "author": {
      "@type": "Organization",
      "name": "Ultrafine",
      "url": "https://www.ultrafine.co"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Ultrafine",
      "logo": {
        "type": "ImageObject",
        "url": "https://www.ultrafine.co/ultrafine-logo.jpg"
      }
    }
  }`;

  return (
    <Layout pageTitle={data.mdx.frontmatter.title} pageClass="template-article template-article--flush" meta={meta} location={location} schema={schema}>
      <PageTransition>
        <div className="article">
          <div className="grid">
            <GatsbyImage
              className="article__image"
              image={getImage(data.mdx.frontmatter.heroArticles)}
              alt={data.mdx.frontmatter.hero_image_alt}
            />
            {data.mdx.frontmatter.hero_image_credit_link && <p className="article__photo-credit article__photo-credit--mobile">
              Photo Credit:{" "}
              <a href={data.mdx.frontmatter.hero_image_credit_link} target="_blank">
                {data.mdx.frontmatter.hero_image_credit_text}
              </a>
            </p>}
          </div>
          <div className="container grid">
            <div className="article__content-wrapper grid">
              <article className="article__article">
              {data.mdx.frontmatter.hero_image_credit_link && <p className="article__photo-credit article__photo-credit--desktop">
              Photo Credit:{" "}
                <a href={data.mdx.frontmatter.hero_image_credit_link} target="_blank">
                  {data.mdx.frontmatter.hero_image_credit_text}
                </a>
              </p>}
                <header className="article__header">
                  <h1 className="article__title">{data.mdx.frontmatter.title}</h1>
                </header>
                <div className="article__main">
                  <div className="article__body md">
                    <MDXRenderer>
                      {data.mdx.body}
                    </MDXRenderer>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <Testimonials />
        <Press />
      </PageTransition>
    </Layout>
  )
}

export default ArticleTemplate